<template>
  <div class="singerIndex">
    <div class="navBar">
      <!--  地区   -->
      <ul>
        <li><span> 语种: </span></li>
        <li style="cursor: pointer"
            v-for="(item, index) in navBar.area"
            :key="index"
            @click="areaChange(item)">
          <span :class="{item_active: item.name === area_}">
            {{ item.name }}
          </span>
        </li>
      </ul>
      <!--  分类   -->
      <ul>
        <li><span> 分类: </span></li>
        <li style="cursor: pointer"
            v-for="(item, index) in navBar.type"
            :key="index"
            @click="sortChange(item)">
          <span :class="{item_active: item.name === type_}">
            {{ item.name }}
          </span>
        </li>
      </ul>
      <!--  筛选    -->
      <ul>
        <li><span> 筛选: </span></li>
        <li style="cursor: pointer;margin-bottom:1vw;"
            v-for="(item, index) in initial"
            :key="index"
            @click="initialChange(item)">
          <span :class="{item_active: item === initial_}"> {{ item }} </span>
        </li>
      </ul>
    </div>
    <!-- 歌手   -->
    <div class="item"
         v-for="(item, index) in artists"
         :key="index">
      <div class="info-wrap">
        <img v-lazy="item.img1v1Url"
             style="cursor: pointer"
             @click="singerDetailJump(item)">
        <div style="margin:10px 0 0 20px"><span>{{ item.name }}</span></div>
      </div>
    </div>
    <div class="nav">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="num"
                     :page-sizes="[10, 15, 20, 30]"
                     :page-size="size"
                     :layout="pagi"
                     background
                     :total="950">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { singerClassificationAPI } from '@/api/api'

export default {
  name: 'singerIndex',
  data () {
    return {
      navBar: {
        area: [
          { name: '全部', id: -1 },
          { name: '华语', id: 7 },
          { name: '欧美', id: 96 },
          { name: '日本', id: 8 },
          { name: '韩国', id: 16 },
          { name: '其他', id: 0 }
        ],
        type: [
          { name: '全部', id: -1 },
          { name: '男歌手', id: 1 },
          { name: '女歌手', id: 2 },
          { name: '乐队组合', id: 3 }
        ]
      },
      initial: [
        '热门',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        '#'
      ],
      queryInfo: {
        type: -1,
        area: -1,
        limit: 10,
        offset: 0,
        initial: -1
      },
      type_: '全部',
      area_: '全部',
      initial_: '热门',
      artists: [], // axios返回的歌手数据
      num: 1,
      size: 0,
      pagi: 'total, sizes, prev, pager, next, jumper'
    }
  },
  async created () {
    this.getSingerInfo()
  },
  methods: {
    async getSingerInfo () {
      const { data: res } = await singerClassificationAPI(this.queryInfo)
      this.artists = res.artists
      this.num = 1
    },
    areaChange (item) {
      this.num = 1
      this.queryInfo.area = item.id
      this.area_ = item.name
      this.getSingerInfo()
      this.num = 1
    },
    sortChange (item) {
      this.queryInfo.type = item.id
      this.type_ = item.name
      this.getSingerInfo()
      this.num = 1
    },
    initialChange (item) {
      if (item === '热门') {
        this.queryInfo.initial = -1
      } else {
        this.queryInfo.initial = item
      }
      this.initial_ = item
      this.getSingerInfo()
      this.num = 1
    },
    singerDetailJump (item) {
      this.$router.push(`/singerDetail?id=${item.id}`)
    },
    handleSizeChange (val) {
      this.queryInfo.limit = val
      this.getSingerInfo()
    },
    handleCurrentChange (val) {
      this.queryInfo.offset = (val - 1) * this.queryInfo.limit
      this.getSingerInfo()
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.pagi = 'prev,jumper,next'
    }
  }
}
</script>

<style scoped lang="less">
.navBar {
  ul {
    margin: 20px 0 10px;
    overflow: hidden;
    li {
      float: left;
      margin: 0 10px;
    }
  }
}
.item {
  position: relative;
  top: 0;
  display: inline-block;
  width: 200px;
  margin: 20px 20px 5px;
  .info-wrap {
    width: 200px;
    img {
      width: 100%;
      border-radius: 20px;
    }
    div {
      margin: 5px 0 3px;
    }
  }
}
.item_active {
  color: #ff0000;
}

@media screen and (max-width: 479px) {
  /deep/ .el-pagination__jump {
    margin: 0 2vw !important;
  }
  .el-pagination {
    margin: 2vw 0;
  }
  .navBar ul {
    font-size: 13px;
    margin: 3vw 0 0 0;
  }
  .navBar ul li {
    margin-left: 2vw;
  }
  .item {
    width: 26.5vw;
  }
  .item .info-wrap {
    width: 31vw;
  }

  .item .info-wrap span {
    margin: -1vw 0 0 -2vw;
    font-size: 14px;
  }
}
</style>
